
.page-section {
    width: 100%;
    position: relative;
    padding: var(--page-section-padding-y) 0;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        position: relative;
        z-index: 1;
    }
    &--sm {
        padding: var(--page-section-padding-y-sm) 0;
    }
    &--first {
        padding-top: var(--contents-padding-top);
        // margin-top: calc(var(--contents-padding-top) * -1);
    }
    &--last {
        padding-bottom: var(--contents-padding-bottom);
        // margin-bottom: calc(var(--contents-padding-bottom) * -1);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
.grid-section{
    ::v-deep{
        >.container{
            padding: 0;
        }
    }
    &,
    &__contents{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    &__contents{
        padding-left: var(--container-gutter);
        padding-right: var(--container-gutter);
        height: 100%;
    }
    &__left-bg{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .grid-section{
        ::v-deep{
            >.container{
                padding: 0 var(--container-gutter);
            }
        }
        &__contents{
            padding-left: 0;
            padding-right: 0;
            background-image: none !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
@media (max-width: 767px) {
    .grid-section{
        background-image: none !important;
    }
}
