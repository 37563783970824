
.page-section--media{
    background-image: url(/images/sub/media/media-bg.svg);
    background-size: auto 100%;
    background-position: left bottom;
}
.media-card{
    display: block;
    &__thumb{
        position: relative;
        overflow: hidden;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transition: 0.2s ease-out;
        }
    }
    &__btn{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.4);
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-out;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .media-card{
        &:hover{
            .media-card__thumb::after{
                opacity: 1;
                visibility: visible;
                transition: 0.2s ease-out;
            }
            .media-card__btn{
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%) scale(1);
                transition: 0.2s 0.2s ease-out;
            }
        }
    }
}
@media (min-width: 1200px) {
}
