
.card-rows>.card-row-wrap:not(:last-child){
    margin-bottom: 20px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .card-rows>.card-row-wrap:nth-child(2n) .card-row{
        flex-direction: row-reverse;
    }
    .card-rows>.card-row-wrap:not(:last-child){
        margin-bottom: 30px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

