
/* sub-visual */
.sub-visual {
    overflow: hidden;
    position:relative;
    display: flex;
    align-items: center;
    text-align: center;
    height: 280px;
    ::v-deep{
        .container{
            position: relative;
        }
    }
}
.sub-visual__tit-wrap {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    .icon-rounded-object-wrap{
        width: fit-content;
        height: auto;
        position: absolute;
        right: -40px;
        top: -24px;
    }
    .icon-rounded-object{
        position: relative;
        opacity: .6;
    }
}
.sub-visual__bg {
    position: absolute;
    top:0;
    left: 50%;
    z-index: 0;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &__inner{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        &--video{
            ::v-deep{
                video{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative;
                    &[poster]{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, .5);
            }
        }
    }
}
.sub-tab{
    background-color: var(--v-secondary-base);
    ::v-deep{
        >.container{
            padding: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 100vh;
    }
    .sub-tab{
        background-color: transparent;
        ::v-deep{
            >.container{
                padding: var(--container-gutter);
            }
        }
    }
    .sub-visual__tit-wrap {
        margin-bottom: var(--tit-wrap-padding-bottom-lg);
        .icon-rounded-object-wrap{
            right: -120px;
            top: -45px;
        }
    }
}
@media (min-width: 1024px) {
    .sub-visual__tit-wrap {
        margin-bottom: var(--tit-wrap-padding-bottom-lg);
        .icon-rounded-object-wrap{
            right: -120px;
            top: -34px;
        }
    }
}
@media (min-width: 1200px) {
}
